import { render, staticRenderFns } from "./EffectsForm.vue?vue&type=template&id=599ca66d&scoped=true"
import script from "./EffectsForm.vue?vue&type=script&lang=js"
export * from "./EffectsForm.vue?vue&type=script&lang=js"
import style0 from "./EffectsForm.vue?vue&type=style&index=0&id=599ca66d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "599ca66d",
  null
  
)

export default component.exports
import QSelect from 'quasar/src/components/select/QSelect.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSelect,QIcon,QMenu,QCard,QCardSection,QInput,QCheckbox});
